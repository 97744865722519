import * as React from "react"
import {
  ChakraProvider,
  Box,
  theme,
  Image,
  Progress,
  VStack,
  Text,
  Flex
} from "@chakra-ui/react"
import { ProgressBar } from "./ProgressBar"
import { RefObject, useEffect, useRef, useState } from "react";
import GlobalHub from "./SignalR";

const blackShadow = "-1px -1px 1px rgba(0, 0, 0, 1), 1px -1px 1px rgba(0, 0, 0, 1), -1px 1px 1px rgba(0, 0, 0, 1), 1px 1px 1px rgba(0, 0, 0, 1);";
const blackShadow2 = "-1px -1px 5px rgba(0, 0, 0, 0.5), 1px -1px 5px rgba(0, 0, 0, 0.5), -1px 5px 1px rgba(0, 0, 0, 0.5), 1px 5px 1px rgba(0, 0, 0, 0.5);";
const redShadow = "-1px -1px 3px rgba(255, 0, 0, 0.5), 1px -1px 3px rgba(255, 0, 0, 0.5), -1px 1px 3px rgba(255, 0, 0, 0.5), 1px 1px 3px rgba(255, 0, 0, 0.5);";
const greenShadow = "-1px -1px 5px rgba(0, 255, 0, 0.2), 1px -1px 5px rgba(0, 255, 0, 0.2), -1px 1px 5px rgba(0, 255, 0, 0.2), 1px 1px 5px rgba(0, 255, 0, 0.2);";


// extend chakra theme with transparent body background
const customTheme = {
  ...theme,
  styles: {
    global: {
      body: {
        bg: "transparent",
      },
    },
  },
};


export const App = () => {

  const [idling, setIdling] = useState(true);
  const [totalDonations, setTotalDonations] = useState(0);
  const [currentHourProgress, setCurrentHoursProgress] = useState(0);
  const [currentHourDonationsLeft, setCurrentHourDonationsLeft] = useState(0);
  const [dead, setDead] = useState(false);
  const [currentHour, setCurrentHour] = useState(0);
  const videoRef: RefObject<HTMLVideoElement> = useRef(null);

  const [state, setState] = useState(() => new Set());

  // @ts-ignore
  const addItem = item => {
    setState(prev => new Set(prev).add(item));
  }

  // @ts-ignore
  const removeItem = item => {
    setState(prev => {
      const next = new Set(prev);

      next.delete(item);

      return next;
    });
  }

  const hits = [
    "hit1_VP9.webm",
    "hit2_VP9.webm"
  ];

  const multipleHits = [
    "multiple-hits_VP9.webm"
  ];

  const deaths = [
    "death1_VP9.webm",
    "death2_VP9.webm",
    "death3_VP9.webm",
  ]

  const hourCosts = [
    0,
    100,
    250,
    500,
    750,
    1000,
    1500,
    2000,
    2500,
    3000,
    3500,
    4000,
    5000
  ];

  useEffect(() => {
    let callback = (data: any) => {
      console.log(data);
      addDonation(data.amount);
    };

    GlobalHub.addListener("DonationAdded", callback);
    return function cleanup() {
      GlobalHub.removeListener("DonationAdded", callback);
    };
  }, [state]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    videoRef.current.addEventListener('ended', myHandler, false);
    function myHandler() {
      setIdling(true);

    }

    return () => {
      videoRef.current?.removeEventListener('ended', myHandler);
    };
  }, [videoRef]);

  useEffect(() => {
    let currentHour = 0;
    let dead = false;
    let newHour = false;
    for (let i = 0; i < hourCosts.length; i++) {

      if (totalDonations < hourCosts[i]) {
        currentHour = i;
        break;
      }

      if (i === hourCosts.length - 1) {
        dead = true;
        currentHour = i;
      }
    }

    let donationsLeft = hourCosts[currentHour] - totalDonations;
    let percent = donationsLeft / (hourCosts[currentHour] - (currentHour > 0 ? hourCosts[currentHour - 1] : 0));

    if (dead) {
      setCurrentHoursProgress(0);
    } else {
      setCurrentHourDonationsLeft(donationsLeft);
      setCurrentHoursProgress(percent);
    }
    setCurrentHour(currentHour);
    setDead(dead);

  }, [totalDonations]);

  useEffect(() => {
    let timeout = setInterval(async () => {
      fetch("https://api.hyphonix.gg/v1/donations")
        .then(response => response.text())
        .then(text => setTotalDonations(parseFloat(text)));
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  function addDonation(amount: number) {

    let crossedIntoNewHour = false;


    let item = { id: Date.now(), amount: amount, offsetX: Math.random() * 100, offsetY: Math.random() * 100 };
    addItem(item);

    if (state.size == 3 || (state.size >= 3 && idling)) {
      hit(true);
    }
    else if (state.size < 3 && idling) {
      hit(false);
    }
    setTimeout(() => {
      removeItem(item);
    }, 3000);

    if (totalDonations + amount >= hourCosts[currentHour] && totalDonations < hourCosts[currentHour]) {
      die();
    }

    setTotalDonations(donations => donations + amount);
  }

  function hit(multiple: boolean) {
    setIdling(false);
    if (!videoRef.current) {
      return;
    }

    if (multiple) {
      videoRef.current.src = multipleHits[Math.floor(Math.random() * multipleHits.length)];
    } else {
      videoRef.current.src = hits[Math.floor(Math.random() * hits.length)];
    }

    videoRef.current.currentTime = 0;
    videoRef.current.play();
  }

  function die() {
    setIdling(false);
    if (!videoRef.current) {
      return;
    }

    videoRef.current.src = deaths[Math.floor(Math.random() * deaths.length)];
    videoRef.current.currentTime = 0;
    videoRef.current.play();
  }

  return (
    <ChakraProvider theme={customTheme}>
      <Box textAlign="center" fontSize="xl" position="relative" w="400px">
        <Flex position="absolute" left={0} right={0} top="110px" justifyContent="center" flexDirection="row">
          <Image src="island.png" width="300px" />
        </Flex>
        <Flex position="absolute" left={0} right={0} top="10px" justifyContent="center" flexDirection="row">
          <Text fontSize="2xl" fontFamily="Matiz" textShadow={blackShadow} color="white">Total: ${totalDonations.toFixed(2)}</Text>
        </Flex>
        <Box position="absolute" left={0} right={0} display={idling ? 'none' : 'visible'}>
          <video ref={videoRef} autoPlay={true} muted={true} loop={false} />
        </Box>
        <Box position="absolute" left={0} right={0} display={idling ? 'visible' : 'none'}>
          <video src="idle_VP9.webm" autoPlay={true} muted={true} loop={true} />
        </Box>
        {/* <Flex position="absolute" left={0} right={0} top="50px" justifyContent="center" flexDirection="row">
          <Text fontSize="2xl" fontFamily="Matiz" textShadow={greenShadow}>Daemon was gifted by Plexus!</Text>
        </Flex> */}

        <VStack spacing={0} position="absolute" left={0} right={0} top="200px">
          <Text fontSize="lg" fontFamily="Matiz" textShadow={redShadow} color="white">Hyphonix, Lvl {24 + currentHour}</Text>
          <Text fontSize="2xl" fontFamily="Matiz" color="#ff0000" textShadow={blackShadow}>{dead ? "Dead." : `$${currentHourDonationsLeft.toFixed(2)} HP`}</Text>
          <ProgressBar value={currentHourProgress} />
        </VStack>
        <Box position="absolute">
          {Array.from(state).map((item, index) => (
            // @ts-ignore
            <Box key={item.id} position="absolute" left={`${100 + item.offsetX}px`} top={`${35 + item.offsetY}px`}>
              {/* @ts-ignore */}
              <Text opacity={0.75} color="white" fontSize="md" fontFamily="Matiz" textShadow={blackShadow}>+${item.amount}</Text>
            </Box>
          ))}
        </Box>
      </Box>
    </ChakraProvider >
  )
};