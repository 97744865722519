import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";

class SignalR {

    connection: HubConnection | null = null;

    constructor() {
        this.connection = new HubConnectionBuilder()
            .withUrl("https://api.hyphonix.gg/hubs/donations")
            .withAutomaticReconnect([0, 2, 10, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30])
            .build();
    }

    init() {
        return this.connection?.start()
            .catch((e) => console.log("Connection failed: ", e));
    }

    addListener(onMsg: string, func: any) {
        this.connection?.on(onMsg, func);
    }

    removeListener(onMsg: string, cb: any) {
        this.connection?.off(onMsg, cb)
    }
};

export default new SignalR();